<template>
    <div class="main">
        <div class="title">Pre Numbers</div>
        <div class="pre-numbers">
            <div class="number-item" v-for="(item, index) in getPreNumbers()"
                :style="{ backgroundColor: item.color, color: getColorText(item.color) }" :key="index">{{ item.number }}
            </div>
        </div>
        <div class="title">Numbers</div>
        <div class="numbers">
            <div class="number-item" v-for="(item, index) in   getNumbers()  "
                :style="{ backgroundColor: item.color, color: getColorText(item.color) }" :key="index">
                <div class="clover" v-if="isClover(index)" :style="{ backgroundImage: `url( ${imagePath()} )` }">
                    {{ item.number }}
                </div>
                <div v-else>
                    {{ item.number }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        roundData: {
            type: Object,
            required: true,
            default() {
                return {
                    numbers: undefined
                }
            }
        },
        tickets: {
            type: Array,
            required: true,
            default() {
                return [{
                    bet: undefined,
                    win: undefined,
                    total: undefined,
                    player_id: undefined,
                }]
            }
        }
    },
    methods: {
        getPreNumbers() {
            const preNumbers = this.roundData.numbers.slice(0, 5);
            return preNumbers;
        },
        getNumbers() {
            const numbers = this.roundData.numbers.slice(5);
            return numbers;
        },
        isClover(index) {
            const positions = this.roundData.cloverPositions;
            for (let i = 0; i < positions.length; i++) {
                if ((positions[i] - 5) == index) {
                    return true;
                }
            }
            return false;
        },
        imagePath() {
            return require(`@/assets/games/realtime/luckysix/clover.png`);
        },
        getColorText(color) {
            if (color == 'yellow') {
                return 'black';
            } else {
                return 'white';
            }
        }
    },
    name: "LuckySixModal"
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pre-numbers {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.number-item {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 0 10px 10px 0;
}

.numbers {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    margin-top: 10px;
    overflow-x: auto;
    height: 150px;
}

.title {
    margin: 0 auto;
    font-size: 20px;
    margin-top: 10px;
}

.clover {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
